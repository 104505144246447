import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Container, Section } from "../global"

const Cennik = () => {
  const [signupLink, setSignupLink] = useState("https://app.zioom.pl/signup")

  useEffect(() => {
    if (typeof window !== "undefined" && window.outerWidth <= 575)
      setSignupLink("https://zioom.pl/registermobile")
  }, [])

  return (
    <StyledSection id="cennik">
      <CennikContainer>
        <div className="chips">
          <Subtitle>CENNIK</Subtitle>
        </div>
        <SectionTitle>Wybierz swój pakiet</SectionTitle>
        <SectionDescription>
          <span className="start">Zacznij z planem Faktury</span> bez limitu
          ilości faktur i czasu.
          {"  "}
        </SectionDescription>
        <OfferContainer>
          <div className="offer__top">
            <p className="offer__top__price">0 zł</p>
            <p className="offer__top__title">Faktury</p>
            <span>Wszystkie podstawowe cechy programu do fakturowania</span>
          </div>
          <div className="offer__bottom">
            <div className="offer__bottom__row">
              <Check>
                <FontAwesomeIcon icon={faCheck} />
              </Check>{" "}
              <span>Wszystkie rodzaje faktur</span>
            </div>
            <div className="offer__bottom__row">
              <Check>
                <FontAwesomeIcon icon={faCheck} />
              </Check>{" "}
              <span>Zapłaty i terminy płatności</span>
            </div>
            <div className="offer__bottom__row">
              <Check>
                <FontAwesomeIcon icon={faCheck} />
              </Check>{" "}
              <span>Podstawowe zestawienia</span>
            </div>
            <div className="offer__bottom__row">
              <Check>
                <FontAwesomeIcon icon={faCheck} />
              </Check>{" "}
              <span>Jeden Użytkownik</span>
            </div>
            <div className="button">
              <a href={signupLink} target="blank">
                Wystartuj
              </a>
            </div>
          </div>
        </OfferContainer>
      </CennikContainer>
    </StyledSection>
  )
}

export default Cennik

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  padding: 112px 0 108px 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    background-image: linear-gradient(#f8fbff, #fcfeff);
    padding: 64px 0 60px 0;
  }
`

const CennikContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .chips {
    width: 84px;
    height: 32px;
    border-radius: 36px;
    background-color: ${props => props.theme.color.background.green};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0 24px;
  }
`

const Subtitle = styled.p`
  font-family: ${props => props.theme.font.primary.bold};
  font-size: ${props => props.theme.font_size.xxsmall};
  color: ${props => props.theme.color.accentGreen};
  line-height: 1.43;
  text-align: center;
  margin: 6px 0 0 0;
`

const SectionTitle = styled.p`
  font-family: ${props => props.theme.font.primary.light};
  font-size: ${props => props.theme.font_size.xxlarge};
  color: ${props => props.theme.color.primary.high};
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 16px 0 0 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-family: ${props => props.theme.font.primary.regular};
    font-size: 28px;
    line-height: 36px;
    padding: 0 40px;
  }
`

const SectionDescription = styled.p`
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.primary.medium};
  text-align: center;
  margin: 28px 0 0 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-family: ${props => props.theme.font.primary.regular};
    font-size: ${props => props.theme.font_size.xsmall};
    color: ${props => props.theme.color.primary.high};
    line-height: 1.75;
    padding: 0 24px;
    margin: 52px 0 0 0;
  }

  .start {
    font-family: ${props => props.theme.font.primary.regular};
    color: ${props => props.theme.color.primary.high};

    @media (max-width: ${props => props.theme.screen.md}) {
      font-family: ${props => props.theme.font.primary.bold};
    }
  }

  a {
    font-family: ${props => props.theme.font.primary.regular};
    color: ${props => props.theme.color.accentBlue};
    text-decoration: none;

    @media (max-width: ${props => props.theme.screen.md}) {
      display: none;
    }
  }
`

const OfferContainer = styled.div`
  max-width: 320px;
  height: 572px;
  border-radius: 4px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
  background-color: ${props => props.theme.color.background.white};
  border-top: 3px solid #3f598a;
  padding: 48px 36px;
  margin: 64px 0 0 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 38px 0 0 0;
  }

  .offer__top {
    padding-bottom: 24px;
    border-bottom: 1px solid #e5eaf4;

    .offer__top__price {
      font-family: ${props => props.theme.font.primary.regular};
      font-size: ${props => props.theme.font_size.xxxlarge};
      color: ${props => props.theme.color.primary.high};
      margin: 0;

      @media (max-width: ${props => props.theme.screen.md}) {
        font-family: ${props => props.theme.font.primary.regular};
      }
    }

    .offer__top__title {
      font-family: ${props => props.theme.font.primary.regular};
      font-size: ${props => props.theme.font_size.xlarge};
      color: ${props => props.theme.color.primary.high};
      line-height: 1.33;
      margin-bottom: 10px;
      margin-top: 4px;
      letter-spacing: 0.2px;
      margin: 8px 0;

      @media (max-width: ${props => props.theme.screen.md}) {
        font-family: ${props => props.theme.font.primary.regular};
        font-size: ${props => props.theme.font_size.regular};
        line-height: 1.2;
      }
    }

    span {
      font-family: ${props => props.theme.font.primary.regular};
      font-size: ${props => props.theme.font_size.xsmall};
      color: ${props => props.theme.color.primary.medium};
      line-height: 1.75;
      margin: 10px 0 32px 0;

      @media (max-width: ${props => props.theme.screen.md}) {
        font-size: ${props => props.theme.font_size.xxsmall};
      }
    }
  }

  .offer__bottom {
    padding-top: 32px;
    display: grid;
    grid-template-rows: 24px 24px 24px 24px 120px;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;

    .offer__bottom__row {
      font-family: ${props => props.theme.font.primary.light};
      font-size: ${props => props.theme.font_size.xsmall};
      color: ${props => props.theme.color.primary.high};
      display: grid;
      grid-template-columns: 36px 1fr;
      align-items: center;
      width: 100%;

      @media (max-width: ${props => props.theme.screen.md}) {
        font-family: ${props => props.theme.font.primary.regular};
        font-size: ${props => props.theme.font_size.xxsmall};
      }
    }

    .button {
      border: 2px solid ${props => props.theme.color.accentBlue};
      border-radius: 8px;
      width: 208px;
      height: 48px;
      padding: 12px 0;
      text-align: center;

      a {
        font-family: ${props => props.theme.font.primary.bold};
        font-size: ${props => props.theme.font_size.xsmall};
        color: ${props => props.theme.color.accentBlue};
        text-decoration: none;
      }
    }
  }
`

const Check = styled.div`
  background-color: ${props => props.theme.color.background.green};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${props => props.theme.color.accentGreen};
  text-align: center;
  font-size: 10px;
  padding-top: 8px;
`
