import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

const MobileNavStyle = styled.div`
  position: fixed;
  display: block;
  background-color: ${props => props.theme.color.background.white};
  padding: 8px 24px 0 28px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  .children {
    display: flex;
    height: 44px;
    align-items: center;
  }
`

const MobileNavBar = styled.div`
  display: flex;
  height: 43px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .icon {
    font-size: 24px;
  }
`

const MobileNav = props => {
  const { toggleNav, children } = props
  return (
    <MobileNavStyle>
      <MobileNavBar>
        <div className="logo">
          <span className="logo__text">ZIOOM</span>
          <div className="logo__dot"></div>
        </div>
        <FontAwesomeIcon className="icon" icon={faTimes} onClick={toggleNav} />
      </MobileNavBar>
      <div className="children">{children}</div>
    </MobileNavStyle>
  )
}

export default MobileNav
