import React, { useState, useEffect } from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import Cennik from "../components/sections/cennik"

const IndexPage = () => {
  const [komunikat, setKomunikat] = useState("")

  useEffect(() => {
    const WEBSOCKET_URL = "wss://app.zioom.pl:8090"
    const s = new WebSocket(WEBSOCKET_URL)
    s.onmessage = e => {
      if (e.data) {
        const odp = JSON.parse(e.data)
        if (odp.Id === "KOMUNIKAT") {
          if (odp.Dane) setKomunikat(odp.Dane.Tresc)
          else setKomunikat("")
        }
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="ZIOOM" />
      <Header komunikat={komunikat} />
      <Features />
      <Cennik />
      <Footer />
    </Layout>
  )
}

export default IndexPage
