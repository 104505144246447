import React, { useState, useEffect } from "react"
import MobileNav from "./mobile"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-light-svg-icons"

const FullNavBar = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 124px;
  justify-content: space-between;
  align-items: center;

  .button {
    border: 2px solid ${props => props.theme.color.accentBlue};
    border-radius: 8px;
    width: 132px;
    height: 48px;
    padding: 12px 28px;

    a {
      font-family: ${props => props.theme.font.primary.bold};
      font-size: ${props => props.theme.font_size.xsmall};
      color: ${props => props.theme.color.accentBlue};
      text-decoration: none;
      text-align: left;
    }
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`

const MobileNavBar = styled.div`
  display: none;
  width: 100%;
  padding: 0 8px 0 12px;
  height: 48px;
  justify-content: space-between;
  align-items: end;
  .icon {
    font-size: 24px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
`

const LoginButtonStyle = styled.a`
  text-decoration: none;
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.xsmall};
  line-height: 1.75;
  color: ${props => props.theme.color.primary.medium};
`

const Navigation = () => {
  const [mobileActive, setMobileActive] = useState(false)
  const [loginLink, setLoginLink] = useState("https://app.zioom.pl/login")

  useEffect(() => {
    if (typeof window !== "undefined" && window.outerWidth <= 575)
      setLoginLink("https://zioom.pl/loginmobile")
  }, [])

  function toggleNav() {
    setMobileActive(!mobileActive)
  }
  return (
    <nav style={{ width: "100%" }}>
      <FullNavBar>
        <div className="logo">
          <span className="logo__text">ZIOOM</span>
          <div className="logo__dot"></div>
        </div>
        <div className="button">
          <a href={loginLink} target="_blank" rel="noopener noreferrer">
            ZALOGUJ
          </a>
        </div>
      </FullNavBar>
      <MobileNavBar>
        <div className="logo">
          <span className="logo__text">ZIOOM</span>
          <div className="logo__dot"></div>
        </div>
        <FontAwesomeIcon className="icon" icon={faBars} onClick={toggleNav} />
      </MobileNavBar>
      {mobileActive && (
        <MobileNav toggleNav={toggleNav}>
          <LoginButtonStyle
            href={loginLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            ZALOGUJ
          </LoginButtonStyle>
        </MobileNav>
      )}
    </nav>
  )
}

export default Navigation
