import React from "react"
import styled from "styled-components"
import Cloud from "../../images/features/cloud.svg"
import Cooperation from "../../images/features/cooperation.svg"
import Home from "../../images/features/home.svg"
import Reports from "../../images/features/reports.svg"
import Shield from "../../images/features/shield.svg"
import Thumb from "../../images/features/thumb.svg"
import { Container, Section } from "../global"

const Features = () => {
  return (
    <StyledSection id="features">
      <Container>
        <Subtitle>MOŻLIWOŚCI</Subtitle>
        <SectionTitle>Jedyny taki program do faktur online</SectionTitle>
        <SectionTitleMobile>Program do faktur online</SectionTitleMobile>
        <SectionDescription>
          Wystawiaj i wysyłaj faktury online bez limitów i ograniczeń. <br />{" "}
          Kontroluj zapłaty i terminy płatności.
        </SectionDescription>
        <SectionDescriptionMobile>
          Wystawiaj i wysyłaj faktury online
          <br />
          bez limitów i ograniczeń.
          <br />
          Kontroluj zapłaty i terminy płatności.
        </SectionDescriptionMobile>
        <FeaturesGrid>
          <FeatureItem>
            <img src={Home} alt="home" />
            <div>
              <FeatureTitle>Wszystkie rodzaje faktur</FeatureTitle>
              <FeatureText>
                Faktura VAT, faktura walutowa, wewnątrzwspólnotowa, zaliczkowa,
                VAT marża i proforma.
              </FeatureText>
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={Shield} alt="shield" />
            <div>
              <FeatureTitle>Kontrola płatności</FeatureTitle>
              <FeatureText>
                Zapłaty do faktur, kontrola terminów płatności i wartości
                niezapłaconych faktur
              </FeatureText>
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={Reports} alt="reports" />
            <div>
              <FeatureTitle>Zestawienia</FeatureTitle>
              <FeatureText>
                Najlepsi klienci, najlepsze towary i usługi w podziale na
                wartość i ilość. Eksporty do Excela.
              </FeatureText>
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={Cooperation} alt="cooperation" />
            <div>
              <FeatureTitle>Współpraca z księgowym</FeatureTitle>
              <FeatureText>
                Zaproś do współpracy księgowego lub przesyłaj faktury online do
                Biura Rachunkowego.
              </FeatureText>
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={Cloud} alt="cloud" />
            <div>
              <FeatureTitle>Praca w chmurze</FeatureTitle>
              <FeatureText>
                Pracuj z dowolnego miejsca online o dowolnej porze. Pracujemy
                24x7 365 dni w roku.
              </FeatureText>
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={Thumb} alt="thumb" />
            <div>
              <FeatureTitle>100% niezawodności</FeatureTitle>
              <FeatureText>
                Wykorzystujemy niezawodność Microsoft Azure i szyfrujemy dane
                bezpiecznym połączeniem SSL.
              </FeatureText>
            </div>
          </FeatureItem>
        </FeaturesGrid>
      </Container>
    </StyledSection>
  )
}

export default Features

const StyledSection = styled(Section)`
  padding: 84px 0 134px 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    background-image: linear-gradient(#f8fbff, white);
    padding: 52px 0 64px 0;
  }
`
const Subtitle = styled.p`
  font-family: ${props => props.theme.font.primary.bold};
  font-size: ${props => props.theme.font_size.xxsmall};
  color: ${props => props.theme.color.accentGreen};
  letter-spacing: 2px;
  margin-bottom: 12px;
  text-align: center;
`

const SectionTitle = styled.p`
  font-family: ${props => props.theme.font.primary.light};
  color: ${props => props.theme.color.primary.high};
  font-size: ${props => props.theme.font_size.xxlarge};
  line-height: 1.33;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 16px 0 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const SectionTitleMobile = styled.p`
  display: none;
  font-family: ${props => props.theme.font.primary.regular};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin: 16px 0 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`

const SectionDescription = styled.p`
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.primary.medium};
  line-height: 1.78;
  text-align: center;
  margin: 16px 0 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const SectionDescriptionMobile = styled.p`
  display: none;
  font-family: ${props => props.theme.font.primary.light};
  font-size: ${props => props.theme.font_size.xsmall};
  color: ${props => props.theme.color.primary.medium};
  line-height: 1.75;
  text-align: center;
  margin: 16px 0 0;
  padding: 0 24px;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 64px 0 0 0;
  grid-column-gap: 62px;
  grid-row-gap: 50px;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 48px auto;
    grid-template-columns: 1fr;
    padding: 0 28px;
  }
`

const FeatureItem = styled.div`
  display: grid;
  grid-template-columns: 48px auto;
  grid-gap: 12px;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 20px;
  }
`

const FeatureTitle = styled.p`
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.large};
  color: ${props => props.theme.color.primary.high};
  line-height: 1.52;
  margin-bottom: 0px;
  margin-top: 4px;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
    font-size: ${props => props.theme.font_size.regular};
  }
`

const FeatureText = styled.p`
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.primary.medium};
  line-height: 1.78;
  margin: 8px 0 0 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
    font-size: ${props => props.theme.font_size.xsmall};
    margin: 16px 0 0 0;
  }
`
