import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Shape from "../../images/header/combined-shape-copy.svg"
import TouchIcon from "../../images/header/icon-touch.svg"
import Navigation from "../common/navigation/navigation"
import { Container } from "../global"

const Header = props => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "mac-book" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  const [signupLink, setSignupLink] = useState("https://app.zioom.pl/signup")

  useEffect(() => {
    if (typeof window !== "undefined" && window.outerWidth <= 575)
      setSignupLink("https://zioom.pl/registermobile")
  }, [])

  return (
    <HeaderWrapper id="top">
      <Container>
        <Navigation />
        {props.komunikat && <Komunikat>{props.komunikat}</Komunikat>}
        <Flex>
          <HeaderTextGroup>
            <p className="title">
              Faktury online
              <br /> dla wszystkich
            </p>
            <p className="desc">
              Wystawiaj faktury online bez limitów i ograniczeń. Kontroluj
              zapłaty i terminy płatności.
            </p>
            <TryItContainer>
              <img src={Shape} alt="shape" />
              <div className="button">
                <a href={signupLink} target="blank">
                  Wypróbuj za darmo
                </a>
              </div>
            </TryItContainer>
            <ScrollContainer>
              <a href="#features">
                <img src={TouchIcon} alt="touch" />
              </a>
              <span className="scroll">Przewiń</span>
              <span>aby zobaczyć możliwości programu</span>
            </ScrollContainer>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
          <TryItMobile>
            <div className="button">
              <a href={signupLink} target="blank">
                Wypróbuj za darmo{"\xa0\xa0"}
              </a>
            </div>
          </TryItMobile>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 108px;

  span {
    font-family: ${props => props.theme.font.primary.medium};
    color: ${props => props.theme.color.secondary.medium};
  }

  .scroll {
    font-family: ${props => props.theme.font.primary.medium};
    color: ${props => props.theme.color.secondary.high};
    margin: 0 4px 0 12px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background.light};
  padding: 32px 0 80px 0;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
    background-color: ${props => props.theme.color.background.white};
    padding: 16px 0 74px 0;
  }
`
const Komunikat = styled.div`
  background-color: ${props => props.theme.color.errorBackground};
  color: ${props => props.theme.color.errorText};
  font-family: ${props => props.theme.font.primary.regular};
  font-size: ${props => props.theme.font_size.regular};
  text-align: center;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -90px;
  margin-bottom: 20px;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 24px;
  }

  > div {
    width: 120%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  .title {
    margin: 0;
    color: ${props => props.theme.color.primary.high};
    font-family: ${props => props.theme.font.primary.light};
    font-size: ${props => props.theme.font_size.xxxxlarge};
    line-height: 1.21;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-family: ${props => props.theme.font.primary.regular};
      font-size: 32px;
      line-height: 1.25;
      text-align: center;
    }
  }

  .desc {
    margin: 24px 0 0 0;
    font-family: ${props => props.theme.font.primary.regular};
    color: ${props => props.theme.color.primary.high};
    font-size: ${props => props.theme.font_size.regular};
    line-height: 1.8;
    @media (max-width: ${props => props.theme.screen.md}) {
      color: ${props => props.theme.color.primary.medium};
      font-size: ${props => props.theme.font_size.xsmall};
      line-height: 1.75;
      text-align: center;
      padding: 0 28px;
      margin-top: 22px;
    }
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }
`
const TryItContainer = styled.div`
  img {
    width: 110px;
    position: absolute;
    right: 36%;
    top: 54%;

    @media (max-width: ${props => props.theme.screen.lg}) {
      display: none;
    }
  }

  .button {
    position: relative;
    margin-top: 48px;
    background-color: ${props => props.theme.color.accentBlue};
    text-align: center;
    width: 258px;
    border-radius: 8px;
    box-shadow: beige;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    a {
      color: white;
      height: 54px;
      line-height: 3;
      text-decoration: none;
      font-size: ${props => props.theme.font_size.small};
      font-family: ${props => props.theme.font.primary.bold};
    }

    @media (max-width: ${props => props.theme.screen.md}) {
      display: none;
    }
  }
`

const TryItMobile = styled.div`
  justify-self: center;
  display: none;
  margin-top: 58px;
  .button {
    position: relative;
    background-color: ${props => props.theme.color.accentBlue};
    text-align: center;
    width: 258px;
    border-radius: 8px;
    box-shadow: beige;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    a {
      color: white;
      height: 54px;
      line-height: 3;
      text-decoration: none;
      font-size: ${props => props.theme.font_size.small};
      font-family: ${props => props.theme.font.primary.bold};
    }
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 40px;
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 665px;
  @media (max-width: ${props => props.theme.screen.lg}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 300px;
  }
`
